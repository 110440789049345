import { Grid, Label, Text } from "@sede-x/shell-ds-react-framework";
import { useEffect, useState } from "react";
import { ICurveViewerDrawerProps } from "../../interfaces/ICurveViewerDrawerProps";
import * as Styled from './CurveViewerMain.styles';
import { ProductCurves } from '../../services/ProductCurves';
import { PDSCONSTANT } from "../../common/constants";
import { IProductCurvePricePayload } from "../../interfaces/IProductCurvePricePayload";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Download } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import { UserPermission } from "../../services/UserPermission";
import { ZeroCalEnum } from "../../common/enum";
dayjs.extend(utc);
const dateFormat = PDSCONSTANT.DATE_FORMAT;
const timeFormat = PDSCONSTANT.TIME_FORMAT;
// For rendering date value in Curve Viewer Price Points table
// If Granularity is Higher granularity display time as well alongwith date else date part only
const renderDateValue = (value: any, record: any, index: number) => {
  // Checking if granularity contains 15 min, 30 min, 60 min
  if (!PDSCONSTANT.PDS_HOURLY_GRANULARITITES.includes(record["Granularity"])) {
    // Rendering Date
    value = dayjs(value).format(dateFormat);
    return (<div>{value}</div>)
  }
  else {
    // Rendering Date and Time
    value = dayjs(value).utcOffset(0).format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
    return (<div>{value}</div>)
  }
};

const renderDecimal = (value: any, record: any, index: number) => {
  if (value) {
    value = value.toString()
  }
  return (<div>{value}</div>)
}

const columns = [{
  dataIndex: 'PricePeriodStartDate',
  title: 'Start Date',
  key: 'PricePeriodStartDate',
  render: renderDateValue,
  type: 'Standard'
},
{
  dataIndex: 'PricePeriodEndDate',
  title: 'End Date',
  key: 'PricePeriodEndDate',
  render: renderDateValue,
  type: 'Standard'
},
{
  dataIndex: 'StrikePrice',
  title: 'Strike',
  render: renderDecimal,
  key: 'StrikePrice',
  type: 'Volatility'
},
{
  dataIndex: 'CurvePrice',
  title: 'Price',
  render: renderDecimal,
  key: 'CurvePrice',
  type: 'Standard'
}
];

export default function CurveViewerDrawer(drawerProps: ICurveViewerDrawerProps) {

  const permissions = UserPermission();
  let curvePriceColumns = columns;
  // on change of page number update current page     
  const areas = ['main main', 'subtext .', 'subtext .', 'subtext .', 'subtext .', 'subtext .', 'table table'];
  const maskStyle = { backgroundColor: "black" };
  const headerText = PDSCONSTANT.CURVE_VWR_SIDE_DRAWER_HEADER_LABEL;
  const [productCurvePriceData, setProductCurvePriceData] = useState([]);
  const { GetProductCurvePriceByProductCurve } = ProductCurves();
  const [isProcessing, setIsProcessing] = useState(false);
  const sourceUpdateDate = dayjs(drawerProps.SourceUpdatedDate).isValid() ? dayjs(drawerProps.SourceUpdatedDate).utcOffset(0).format(dateFormat) + " | " + dayjs(drawerProps.SourceUpdatedDate).utc().format(timeFormat) : "";
  const pdsUpdateDate = dayjs(drawerProps.UpdatedDate).isValid() ? dayjs(drawerProps.UpdatedDate).utcOffset(0).format(dateFormat) + " | " + dayjs(drawerProps.UpdatedDate).utc().format(timeFormat) : "";
  const traderApprovedDate = dayjs(drawerProps.TraderApprovalDateTimeUTC).isValid() ? dayjs(drawerProps.TraderApprovalDateTimeUTC).utcOffset(0).format(dateFormat) + " | " + dayjs(drawerProps.TraderApprovalDateTimeUTC).utc().format(timeFormat) : "";

  // trader approval  section
  let traderApprovalSection: any;
  // curveBuildID  section
  let curveBuildIdSection: any;
  const productCurvePricePayload: IProductCurvePricePayload = {

    Name: drawerProps.CurveName,
    EffectiveDate: drawerProps.EffectiveDate,
    UpdatedDate: null,
    SourceUpdatedDate: drawerProps.SourceUpdatedDate,
    CurveBuildId: drawerProps.CurveBuildId,
    Granularity: drawerProps.Granularity,
    TraderApprovalDateTimeUTC: drawerProps.TraderApprovalDateTimeUTC,
    TraderApprovalName: drawerProps.TraderApprovalName,
    TraderApprovalIndicator: drawerProps.TraderApprovalIndicator

  }
  var priceColumn = curvePriceColumns.find(e => e.key === 'CurvePrice');
  if (drawerProps.CurveType !== PDSCONSTANT.VOLATILITY_CURVE_TYPE_VALUE) {
    curvePriceColumns = columns.filter(e => e.type !== PDSCONSTANT.VOLATILITY_CURVE_TYPE_VALUE);

    if (priceColumn) {
      priceColumn.title = PDSCONSTANT.STANDARD_CURVE_PRICE_TITLE;
    }
  }
  else {
    curvePriceColumns = columns;
    if (priceColumn) {
      priceColumn.title = PDSCONSTANT.Volatility_CURVE_PRICE_TITLE;
    }
  }
  //if lite schema, then do not show periodEndDate column
  if (drawerProps.IsLiteSchema != null && drawerProps.IsLiteSchema == true) {
    curvePriceColumns = curvePriceColumns.filter(e => e.key !== 'PricePeriodEndDate');
  }

  if (drawerProps.CurveBuildId) {
    curveBuildIdSection = <>
      <Grid.Cell><Label>Source System Build ID</Label><Text>{drawerProps.CurveBuildId}</Text></Grid.Cell></>
  }
  else {
    curveBuildIdSection = '';
  }

  if (drawerProps.TraderApprovalIndicator) {
    const traderApprovalIndicator = (drawerProps.TraderApprovalIndicator === true ? ZeroCalEnum[ZeroCalEnum.TRUE] : (drawerProps.TraderApprovalIndicator === false ? ZeroCalEnum[ZeroCalEnum.FALSE] : ""));
    traderApprovalSection = <>
      <Grid.Cell ><Label>Trader Approved</Label><Text>{traderApprovalIndicator}</Text></Grid.Cell>
      <Grid.Cell><Label>Approved by</Label><Text>{drawerProps.TraderApprovalName}</Text></Grid.Cell>
      <Grid.Cell><Label>Approval Timestamp (UTC)</Label><Text>{traderApprovedDate}</Text></Grid.Cell>
    </>
  }
  else {
    traderApprovalSection = '';
  }


  // Api calls for Product Curve data
  const getProductCurvePriceData = () => {
    setIsProcessing(true);
    setProductCurvePriceData([]);
    GetProductCurvePriceByProductCurve(productCurvePricePayload).then(result => {
      setProductCurvePriceData(result.data);
      setIsProcessing(false);

    });
  }

  useEffect(() => {
    // Fetch the Product Curve data on load and on change of product curve payload
    getProductCurvePriceData();
  }, [drawerProps.CurveName, drawerProps.EffectiveDate, drawerProps.UpdatedDate, drawerProps.TraderApprovalIndicator]);

  const onLoading = (): string => {
    if (isProcessing) {
      return 'Loading...';
    }
    else {
      return 'No Data';
    }
  }

  const onClose = (): void => {
    drawerProps.onClose();
  };
  return (
    <>
      <Styled.DrawerStyled maskClosable={false} maskStyle={maskStyle} placement="right" open={drawerProps.open} onClose={onClose} header={headerText}>
        <Styled.SideDrawerGrid areas={areas} columns={2}>
          <Grid.Cell area='main'><Label>Name</Label><Text>{drawerProps.CurveName}</Text></Grid.Cell>
          <Grid.Cell><Label>Granularity</Label><Text>{drawerProps.Granularity}</Text></Grid.Cell>
          <Grid.Cell><Label>Curve Type</Label><Text>{drawerProps.CurveType}</Text></Grid.Cell>
          <Grid.Cell><Label>Effective Date</Label><Text>{dayjs(drawerProps.EffectiveDate).utcOffset(0).format(dateFormat)}</Text></Grid.Cell>
          <Grid.Cell><Label>Last Updated (UTC)</Label><Text>{sourceUpdateDate}</Text></Grid.Cell>
          <Grid.Cell><Label>PDS Last Updated (UTC)</Label><Text>{pdsUpdateDate}</Text></Grid.Cell>
          {curveBuildIdSection}
          {traderApprovalSection}
          <Grid.Cell></Grid.Cell>
          <Grid.Cell area='table'> <Styled.TableStyled columns={curvePriceColumns}
            rowKey="curveViewerId"
            key="curveViewerId"
            size='small'
            data={productCurvePriceData}
            emptyText={onLoading}
            scroll={{
              x: 150,
              y: 550
            }}
            tableLayout="fixed"
          />
          </Grid.Cell>
        </Styled.SideDrawerGrid>
        <hr />
        {
          permissions.CanRead_PriceCurve_READ() &&
          <Styled.SideDrawerGrid areas={areas} columns={1}>
            <Grid.Cell> <span style={{ color: "#336ec7", fontSize: "small" }}>Only first 200 curve points are displayed.</span><br /><span style={{ color: "rgb(51 117 199)", fontSize: "12px", fontWeight: "bold" }}>To view the full curve, please click the download csv button</span></Grid.Cell>
            <Grid.Cell> <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' variant='outlined' icon={<Download />} onClick={drawerProps.downloadSelectedCurveData}>Download CSV</Styled.ButtonStyled></Grid.Cell>
          </Styled.SideDrawerGrid>
        }
      </Styled.DrawerStyled>

    </>
  );
}